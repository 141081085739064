<template>
    <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline">Page Not Found</v-card-title>

        <v-card-text>
          Looks like you've followed a broken link or entered a URL that doesn't exist on this site.
        </v-card-text>

        <v-card-actions class="text-align">
           <router-link :to="{path: `/`}" class="back-route">
            Back to our site</router-link
          >
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<style scoped>
.text-align{
  justify-content: center;
}
.back-route{
color:  #F94235 !important;
font-weight: 700;
text-decoration: underline !important;
}
</style>
<script>

export default {
  name: 'AppError',
  data () {
      return {
        dialog: true,
      }
    },
  computed: {},
  methods: {
  },
};
</script>
